import AppConfig from '@Common/AppConfig';
import * as SentryBrowser from '@sentry/browser';

export default class Sentry {
    /**
     * @param {Array} config (see Sentry BrowserOptions)
     */
    constructor(config) {
        this.config = config;
        this.enabled = null !== config.dsn;
    }

    static register(container) {
        container.registerParameter('sentry.config', {
            dsn: AppConfig.SENTRY_DSN,
            environment: AppConfig.DEPLOY_ENV,
            release: AppConfig.APP_RELEASE,
            ignoreErrors: [
                /GraphQL error: Invalid payload/,
                /AbortError: The fetching process for the media resource was aborted by the user agent at the user's request/,
                /AbortError: The operation was aborted/,
                /AbortError: The play\(\) request was interrupted by a call to pause\(\)/,
            ],
        });
        container.registerService('sentry', Sentry, ['sentry.config']);
    }

    /**
     * @see https://docs.sentry.io/error-reporting/configuration/?platform=browser
     * @see https://docs.sentry.io/enriching-error-data/context/?platform=browser
     */
    init() {
        if (!this.enabled) {
            // No DSN configured, skip:
            return;
        }

        SentryBrowser.init(this.config);
    }

    setUser(id, email) {
        if (!this.enabled) {
            // No DSN configured, skip:
            return;
        }

        SentryBrowser.configureScope(scope => {
            scope.setUser({ id, username: email, email });
        });
    }

    /**
     * @see https://docs.sentry.io/enriching-error-data/context/?platform=javascript#tagging-events
     *
     * @param {Object} tags
     */
    setTags(tags) {
        if (!this.enabled) {
            // No DSN configured, skip:
            return;
        }

        SentryBrowser.configureScope(scope => {
            scope.setTags(tags);
        });
    }

    /**
     * @see https://github.com/getsentry/sentry-javascript/tree/master/packages/browser#usage
     *
     * @param {String} message
     */
    captureMessage(message) {
        SentryBrowser.captureMessage(message);
    }

    /**
     * @see https://github.com/getsentry/sentry-javascript/tree/master/packages/browser#usage
     *
     * @param {Error} Error
     */
    captureException(error) {
        SentryBrowser.captureException(error);
    }

    /**
     * @see https://github.com/getsentry/sentry-javascript/tree/master/packages/browser#usage
     *
     * @param {Object} message { message, stacktrace }
     */
    captureEvent(event) {
        SentryBrowser.captureEvent(event);
    }
}
