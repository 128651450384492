export type AppConfigScheme = {
  MODE: 'SERVER' | 'BROWSER'
  readonly NODE_ENV: 'production' | 'development'
  readonly DEPLOY_ENV: 'local' | 'staging' | 'production'
  readonly SENTRY_DSN: string | null
  readonly APP_VERSION: string
  readonly APP_RELEASE: string
}

export default AppConfig as AppConfigScheme;
