import { ApolloLink, gql } from '@apollo/client';
import { ErrorLink } from '@apollo/client/link/error';
import { createHttpLink } from '@apollo/client/link/http';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { createUploadLink } from 'apollo-upload-client';
import { extractFiles } from 'extract-files';

const optionsDefaults = {
    batch: true,
    upload: true,
    errorHandler: null,
};

/**
 * @see https://dev-blog.apollodata.com/query-batching-in-apollo-63acfd859862 Query/Mutation batching
 *
 * @param {Array} linkOptions
 *
 * @returns {BatchHttpLink}
 */
function createBatchLink(linkOptions) {
    const factory = () => new BatchHttpLink({
        ...linkOptions,
        uri: linkOptions.uri + 'batch',
        batchMax: 10,
        batchInterval: 20,
    });

    const mainLink = factory();
    const analyticsLink = factory();

    /**
     * Split link to use on specific conditions.
     * E.g: use a different batch link for analytics purposes, so we don't wait for such requests before
     * processing main requests used to render app contents.
     *
     * @see https://www.apollographql.com/docs/link/composition/#directional-composition
     */
    return ApolloLink.split(operation => {
        // Use analytics specific link if the "analytics" tag is set in context
        return operation.getContext().tag === 'analytics';
    }, analyticsLink, mainLink);
}

export function createLink(linkOptions, options) {
    const { batch, upload, errorHandler } = { ...optionsDefaults, ...options };
    const links = [
        batch ? createBatchLink(linkOptions) : createHttpLink(linkOptions)
    ];

    if (upload) {
        links[0] = ApolloLink.split(
            // Use Upload link only for uploads
            operation => extractFiles(operation).files.size > 0,
            createUploadLink(linkOptions),
            links[0]
        );
    }

    if (errorHandler) {
        links.unshift(new ErrorLink(errorHandler));
    }

    return links.length > 1 ? ApolloLink.from(links) : links[0];
}

export const ApolloCacheUtils = {
    /**
     * Helper to update Apollo cache on item creation.
     *
     * @param {String} queryName Query name to modify in cache
     * @param {String} typename Type of data
     * @param {String} dataKey Key to access the data from the mutation response (i.e mutation's name)
     * @param {String} identifier Property acting as uq id for the type of data
     */
    apolloAddItem(queryName, typename, dataKey, identifier = 'id') {
        return (cache, { data }) => {
            cache.modify({
                fields: {
                    [queryName](existing = []) {
                        return [...existing, cache.writeFragment({
                            data: data[dataKey],
                            fragment: gql`
                                fragment NewItem on ${typename} {
                                    ${identifier}
                                }
                            `
                        })];
                    }
                }
            });
        };
    },

    /**
     * Helper to update Apollo cache on item removal.
     *
     * @param {String} dataKey Key to access the data from the mutation response (i.e mutation's name)
     */
    apolloRemoveItem(dataKey) {
        return (cache, { data }) => {
            cache.evict({
                id: cache.identify(data[dataKey]),
            });
            cache.gc();
        };
    },

    /**
     * Purge a whole query results to force re-fetching it on needs.
     *
     * @param {String} queryName Query name to modify in cache
     */
    apolloPurgeQuery(queryName) {
        return (cache) => {
            cache.evict({ id: 'ROOT_QUERY', fieldName: queryName });
            cache.gc();
        };
    },

    /**
     * Removes items from a query according to a mutation result.
     *
     * @param {Function} itemsAccessor How to access, in the response, to the items to remove
     */
    apolloRemoveItemsFromResult(itemsAccessor) {
        return (cache, result) => {
            itemsAccessor(result.data).forEach((item) => {
                cache.evict({
                    id: cache.identify(item),
                });
            });
            cache.gc();
        };
    },
};
