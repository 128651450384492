/**
 * Audio api
 *
 * Documentation: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/audio
 */
export default class AudioApi {
    constructor() {
        this.authorized = false;

        this.togglePlay = this.togglePlay.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onError = this.onError.bind(this);
    }

    /**
     * Lazy-load audio to prevent SSR issues.
     *
     * @return {HTMLAudioElement}
     */
    get audio() {
        if (this._audio) {
            return this._audio;
        }

        this._audio = new Audio();

        this._audio.type = 'audio/mpeg';
        this._audio.preload = 'auto';

        document.body.appendChild(this._audio);

        this.setVolume();

        return this._audio;
    }

    /**
     * Forward event listener to audio element
     */
    addEventListener(...args) {
        this.audio.addEventListener(...args);
    }

    removeEventListener(...args) {
        this.audio.removeEventListener(...args);
    }

    /**
     * Set volume
     *
     * @param {Number} volume
     */
    setVolume(volume = 1) {
        this.audio.volume = volume;
    }

    /**
     * Mute
     *
     * @param  {Boolean|null} muted Toggle mute if null
     */
    mute(muted = null) {
        this.audio.muted = typeof muted === 'boolean' ? muted : !this.audio.muted;
    }

    /**
     * Load the given URL as Audio source
     *
     * @param {String} url
     * @param {Boolean} autoplay
     */
    load(url, autoplay = true) {
        if (url && url !== this.audio.src) {
            this.audio.src = url;

            if (autoplay) {
                this.tryPlay();
            }
        }
    }

    getUrl() {
        return this.audio.src;
    }

    isPlaying() {
        return !this.audio.paused;
    }

    getTime() {
        return this.audio.currentTime;
    }

    getDuration() {
        return this.audio.duration;
    }

    togglePlay() {
        return this.isPlaying() ? this.pause() : this.play();
    }

    /**
     * Play the music
     */
    tryPlay() {
        let promise = null;

        try {
            promise = this.audio.play();
        } catch (error) {
            this.onError(error);
        }

        if (promise) {
            promise.then(this.onSuccess).catch(this.onError);
        } else {
            this.onSuccess();
        }
    }

    /**
     * Start playing music
     */
    play() {
        this.audio.play();
    }

    /**
     * Stop playing music
     */
    pause() {
        this.audio.pause();
    }

    toggle() {
        this.isPlaying() ? this.pause() : this.play();
    }

    /**
     * Move to the given time
     *
     * @param  {Number} time
     */
    seek(time) {
        this.audio.currentTime = time;

        if (!this.isPlaying()) {
            this.play();
        }
    }

    /**
     * Audio API authorized
     */
    onSuccess() {
        this.authorized = true;
        this.audio.dispatchEvent(new Event('authorized'));
    }

    /**
     * Audio API not authorized
     */
    onError(error) {
        if (error instanceof DOMException && error.name === 'NotAllowedError') {
            this.authorized = false;
            this.audio.dispatchEvent(new Event('forbidden'));
        }
    }
}
