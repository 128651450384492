const userTypeField = document.querySelector('.user-type');
const companyFields = document.querySelector('.company-fields');

function toggleCompanyFields() {
    if (userTypeField.value === 'professional') {
        companyFields.style.display = 'block';
    } else {
        companyFields.style.display = 'none';
    }
}

if (userTypeField && companyFields) {
    userTypeField.addEventListener('change', function () {
        toggleCompanyFields();
    });
    toggleCompanyFields();
}

const roleField = document.querySelector('.user-role-field');
const roleStaffFields = document.querySelector('.user-role-staff-field');

function togglRoleStaffFields() {
    if (roleField.value === 'ROLE_STAFF') {
        roleStaffFields.style.display = 'block';
    } else {
        roleStaffFields.style.display = 'none';
    }
}

if (roleField && roleStaffFields) {
    roleField.addEventListener('change', function () {
        togglRoleStaffFields();
    });
    togglRoleStaffFields();
}
